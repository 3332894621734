import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';

import Layout from '../components/layout';
import SEO from '../components/seo';
import './what-we-do.scss';

const mobileIcon = require('../images/icon-mobile-app@3x.png');
const webIcon = require('../images/icon-website@3x.png');
const designIcon = require('../images/icon-design@3x.png');
const downIcon = require('../images/icon-arrow-down-white@3x.png');
const step1 = require('../images/step1.png');
const step2 = require('../images/step2.png');
const step3 = require('../images/step3.png');
const joymapLogo = require('../images/apps/joymap-logo@3x.png');
const MNELogo = require('../images/apps/mynestegg-logo@3x.png');
const trucksterLogo = require('../images/apps/truckster-logo@3x.png');
const localHealthLogo = require('../images/apps/localhealth-logo@3x.png');
const tractorZoomLogo = require('../images/apps/tractorzoom-logo@3x.png');
const lendeduLogo = require('../images/apps/lendedu-logo@3x.png');
const JRLogo = require('../images/apps/jobrepublic-logo@3x.png');
// const flawkLogo = require('../images/apps/flawk-logo@3x.png');

const WhatWeDo = () => {
  const [startApproach, setApproach] = useState(false);
  const [startProduct, setProduct] = useState(false);

  const fade = (delay = 0, opacity = 1) => useSpring({
    opacity, delay, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });
  const textStyle = (index) => (startApproach ? useSpring({
    opacity: 1, transform: 'translateY(0)', delay: index * 100, config: { duration: 300 },
  }) : useSpring({ opacity: 0, transform: 'translateY(60px)' }));
  const productStyle = (index) => (startProduct ? useSpring({
    opacity: 1, transform: 'translateY(0)', delay: index * 100, config: { duration: 300 },
  }) : useSpring({ opacity: 0, transform: 'translateY(60px)' }));

  const onFocusOurApproach = (isVisible) => {
    if (!startApproach) setApproach(isVisible);
  };

  const onFocusProduct = (isVisible) => {
    if (!startProduct) setProduct(isVisible);
  };

  return (
    <Layout backgroundColor="#191d3d">
      <SEO title="What We Do" />
      <div className="WhatWeDo">
        <div className="WhatWeDo__Top">
          <animated.div style={fade(0)} className="WhatWeDo__Title">Build and test your ideas, fast.</animated.div>
          <animated.div style={fade(300, 0.6)} className="WhatWeDo__Description">Launch Deck is a startup studio that moves ideas forward with purpose-driven design and development. Launch Deck partners with inspiring and action-focused founders to build web and mobile apps from the ground up with hands-on product building and remote programs.</animated.div>
          <div className="WhatWeDo__Item WhatWeDo__Item__Mobile" />
          <animated.div style={fade(900)} className="WhatWeDo__Item">
            <img src={mobileIcon} alt="mobile" className="WhatWeDo__Item__img" />
            <div className="WhatWeDo__Item__title">Mobile Apps</div>
            <div className="WhatWeDo__Item__des">Craft an engaging and joyful mobile experience.</div>
          </animated.div>
          <animated.div style={fade(1200)} className="WhatWeDo__Item WhatWeDo__Item_btn">
            <img src={downIcon} alt="down" style={{ width: 24, height: 50 }} />
          </animated.div>
          <animated.div style={fade(1500)} className="WhatWeDo__Item">
            <img src={webIcon} alt="web/app" className="WhatWeDo__Item__img" />
            <div className="WhatWeDo__Item__title">Web/App</div>
            <div className="WhatWeDo__Item__des">Build a thriving SaaS business with a custom web application.</div>
          </animated.div>
          <animated.div style={fade(1800)} className="WhatWeDo__Item">
            <img src={designIcon} alt="design" className="WhatWeDo__Item__img" />
            <div className="WhatWeDo__Item__title">Design</div>
            <div className="WhatWeDo__Item__des">Create a remarkable user experience with empathy and a powerful brand.</div>
          </animated.div>
        </div>
      </div>
      <div className="WhatWeDo__White">
        <VisibilitySensor onChange={onFocusOurApproach} offset={{ top: -200 }}>
          <div>
            <div className="WhatWeDo__White__Top">
              <animated.div style={textStyle(0)} className="WhatWeDo__White__Title">Our approach</animated.div>
              <animated.div style={textStyle(1)} className="WhatWeDo__White__Des">We partner with you on the startup journey. We believe a great product is more than just development - it’s a holistic approach that includes research, design, branding, and marketing. We focus on what’s most important for your business with flexible and iterative problem-solving.</animated.div>
            </div>
            <div className="WhatWeDo__White__Steps">
              <div className="WhatWeDo__White__Steps__Item">
                <animated.img style={textStyle(2)} src={step1} alt="" />
                <div className="WhatWeDo__White__Steps__Item__Info">
                  <animated.div style={textStyle(3)} className="WhatWeDo__White__Steps__Item__Title">Strategy</animated.div>
                  <animated.div style={textStyle(4)} className="WhatWeDo__White__Steps__Item__Des">
                    Define the vision, brand identity,
                    and a clear roadmap for execution.
                  </animated.div>
                </div>
              </div>
              <div className="WhatWeDo__White__Steps__Item">
                <animated.img style={textStyle(5)} src={step2} alt="" />
                <div className="WhatWeDo__White__Steps__Item__Info">
                  <animated.div style={textStyle(6)} className="WhatWeDo__White__Steps__Item__Title">Design</animated.div>
                  <animated.div style={textStyle(7)} className="WhatWeDo__White__Steps__Item__Des">
                    Understand and empathize with users to
                    test concepts and create intentional solutions.
                  </animated.div>
                </div>
              </div>
              <div className="WhatWeDo__White__Steps__Item">
                <animated.img style={textStyle(8)} src={step3} alt="" />
                <div className="WhatWeDo__White__Steps__Item__Info">
                  <animated.div style={textStyle(9)} className="WhatWeDo__White__Steps__Item__Title">Development</animated.div>
                  <animated.div style={textStyle(10)} className="WhatWeDo__White__Steps__Item__Des">
                    Plan and build sprints aligned with
                    business goals and budget.
                  </animated.div>
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
        <div>
          <VisibilitySensor onChange={onFocusProduct} offset={{ top: -400 }}>
            <animated.div style={productStyle(0)} className="WhatWeDo__White__Product__Title">Building products to change the world with early-stage startups</animated.div>
          </VisibilitySensor>
          <div className="WhatWeDo__White__Product__List">
            <animated.img style={productStyle(1)} src={joymapLogo} alt="joymap" />
            <animated.img style={productStyle(2)} src={MNELogo} alt="MNE" />
            <animated.img style={productStyle(3)} src={trucksterLogo} alt="truckster" />
            <animated.img style={productStyle(4)} src={localHealthLogo} alt="local health" />
            <animated.img style={productStyle(5)} src={tractorZoomLogo} alt="tracterzoom" />
            <animated.img style={productStyle(6)} src={lendeduLogo} alt="lendedu" />
            <animated.img style={productStyle(7)} src={JRLogo} alt="jobrepublic" />

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WhatWeDo;
